import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { ConfigService } from '../config.service';
import { LocalizationService } from '../localization.service';
import { CommonService } from '../common.service';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

    constructor(
        private router: Router,
        private data: DataService,
        private config: ConfigService,
        private route: ActivatedRoute,
        private localization: LocalizationService,
        public common: CommonService
    ) {
        this.common.title = this.tr('access.login.title');
        this.common.clearError();
    }

    sessionReady = false;
    discovered = false;
    realms = [];
    //multipleRealms = false;
    selectedRealm = '';
    username = '';
    sessionCount = 3;
    closeSessions = false;
    codeSent = false;
    auth = null;
    user = null;
    codeText = '';
    allowRedirect = true;
    bothMFAEnabled = false;
    preferredMethod = '';
    phoneNumber = '';
    userDetails = null;
    invalidCode = false;
    ssoRedirection = false;
    emailRequired = false;
    passwordRequired = false;
    codeRequired = false;
    skip_mfa_enabled = false;
    isOpsUser = false;
    reset_mfa_triggered = false;
    reset_mfa_submitted = false;
    reset_mfa_device_error = false;
    reset_mfa_too_many_requests_error = false;
    reset_mfa_unknown_error = false;
    user_mfa_setting_list;
    customerId = '';

    mode: ProgressSpinnerMode = 'indeterminate';
    value = 32;
    color: ThemePalette = 'primary';

    ngOnInit() {
        this.initChecks();
    }

    selectValue(val) {
        this.selectedRealm = val;
    }

    saveRealm() {
        this.config.setCookie('AccessRealm', this.selectedRealm, 1);
        this.accessRedirect();
    }

    resetDiscovery() {
        let application = this.config.getParameterByName('application'); 
        if(application && application == 'cloudscan') {
            this.discovered = false;
        }
        else {
            if(this.config.getParameterByName("origin")){
                this.accessRedirect();
            }else{
                this.discovered = false;
            }
        }        
    }

    async initChecks() {
        this.common.title = this.tr('access.login.title');
        this.setTrackingId();
        this.ssoRedirection = true;
        if (this.data.resetSuccessful) {
            this.username = this.data.username;
            await this.discover(this.data.username);           
            this.login(this.data.username, this.data.password, null);
            return;
        }
        let loggedIn = await this.data.isLoggedIn(
            this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
            this.config.getParameterByName('origin') ? this.config.getParameterByName('origin') : 'NA',
            this.config.getParameterByName('target') ? this.config.getParameterByName('target') : 'NA',
            this.config.getParameterByName('loginType') ? this.config.getParameterByName('loginType') : 'NA',
            this.config.getParameterByName('ops') ? this.config.getParameterByName('ops') : 'NA'
        );
        if (loggedIn) {
            this.accessRedirect();
            this.ssoRedirection = false;
        }
        else {          
            var opsPool = this.config.getParameterByName("ops")
            if (!!opsPool) {
                this.isOpsUser = true;
                if(this.config.getParameterByName("loginType") === "emailandpwd") {
                    let res = await this.data.discoverUserPool('', opsPool).toPromise();
                    this.customerId = res.subscription['owner'];
                    this.ssoRedirection = false;
                }  
                else 
                    await this.directIDPLogin("", opsPool, true);                
            }
            else {
                var email = this.config.getParameterByName("email");
                if (!!email) {
                    const username = decodeURIComponent(encodeURIComponent(email));
                    this.username = username;
                    this.ssoRedirection = true;
                    await this.discover(username);
                    return;
                }
                if (this.config.getParameterByName("loginType") == "sso" && this.config.getParameterByName("customerID")) {
                    await this.directIDPLogin(this.config.getParameterByName("customerID"), "")
                    return;
                }
                this.ssoRedirection = false;
            }
        }
    }

    async directIDPLogin(customerid, opsPool, isOpsUser = false) {
        try {
            var identifier, clientId = '';
            let res = await this.data.discoverUserPool(customerid, opsPool).toPromise();
            for (let [key, value] of Object.entries(res.subscription['login_methods'])) {
                if (value['type'] == 'ADFSSAML') {
                    clientId = value['client_id'];
                    identifier = key;
                }
            }
            if (clientId && identifier) {
                await this.redirectToIDP(res.subscription['user_pool_id'], clientId, identifier, isOpsUser, res.subscription['applications']);
            }
        } catch (e) {
            this.ssoRedirection = false;
            this.common.setError(this.tr('access.login.incorrect.config'));
        }
    }

    setTrackingId() {
        let trackingId = this.config.getParameterByName("AccessTrackingID");
        if (trackingId) {
            this.config.setCookie("AccessTrackingID", trackingId, 1);
        }
    }

    async redirectToIDP(userPool: string, clientId: string, identifier: string, isOpsUser: boolean = false, allApplications: {}) {
        let wellKnownUrl = `https://cognito-idp.${userPool.split("_")[0]}.amazonaws.com/${userPool}/.well-known/openid-configuration`;
        let result = await this.data.getWellKnownKeys(wellKnownUrl);

        let opsPool = this.config.getParameterByName("ops");
        if(opsPool && opsPool.toUpperCase() != "BASWARE") {            
            opsPool = "PARTNER"
        }
        // Save some for future use
        localStorage.setItem("amplifyInfo", JSON.stringify({
            isOpsUser: isOpsUser,
            client_id: clientId,
            user_pool: userPool,
            tokenUrl: result["token_endpoint"],
            origin: this.config.getParameterByName('origin'),
            session_count: this.sessionCount,
            languagecode: this.config.getParameterByName('languagecode') || navigator.language,
            application: this.config.getParameterByName('application'),
            allApplications: allApplications,
            tenant: this.config.getParameterByName('tenant'),
            customerID: this.config.getParameterByName('customerID'),
            opsPool: opsPool,
            opsParameter: this.config.getParameterByName('ops')
        }));

        // Determine and build SAML endpoint
        let authorization_endpoint = result["authorization_endpoint"];
        authorization_endpoint += `?response_type=code&client_id=${clientId}&redirect_uri=${this.data.getDomain()}/callout&identity_provider=${identifier}`;
        this.ssoRedirection = true;
        // Redirect to for SAML auth
        location.href = authorization_endpoint;
    }

    async discover(email: string) {
        this.common.beginProgress();
        if (email == ""){
            this.emailRequired = true;
            this.common.stopProgress();
            return;
        }

        try {
            console.log('Discovering');
            const username = email.toLowerCase();
            console.log("MFAPARTNERS - customerId");
            console.log(this.customerId);    
            const discoveredResponse = await this.data.discover(
                username,
                this.config.getParameterByName('customerID') || this.customerId,
                'LOG-IN',
                this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
                this.config.getParameterByName('origin') ? this.config.getParameterByName('origin') : 'NA',
                this.config.getParameterByName('target') ? this.config.getParameterByName('target') : 'NA',
                this.config.getParameterByName('loginType') ? this.config.getParameterByName('loginType') : 'NA',
                this.config.getParameterByName('ops') ? this.config.getParameterByName('ops') : 'NA'
            ).toPromise();
            let decodedToken = new JwtHelperService().decodeToken(discoveredResponse.token); 
            let discovered = decodedToken.userData ? JSON.parse(decodedToken.userData) : {};

            if (discovered) {
                if (discovered.sessionCount) {
                    this.sessionCount = discovered.sessionCount;
                }                
                let loginType = discovered.loginType;
                let skip_mfa_enabled = discovered.skip_mfa_enabled;
                let clientId = discovered.client_id;
                let userPool = discovered.user_pool;
                let applications = discovered.applications;
                this.data.username = email.toLowerCase();
                this.skip_mfa_enabled = skip_mfa_enabled;
                if (loginType.toLowerCase() == "adfssaml") {
                    let identifier = discovered.identifier;
                    this.redirectToIDP(userPool, clientId, identifier, false, applications);
                } else {
                    let opsPool = this.config.getParameterByName("ops");
                    if(opsPool && opsPool.toUpperCase() != "BASWARE") {            
                        opsPool = "PARTNER"
                    }
                    localStorage.setItem("amplifyInfo", JSON.stringify({
                        client_id: clientId,
                        user_pool: userPool,
                        session_count: this.sessionCount,
                        skip_mfa_enabled: skip_mfa_enabled,
                        customer_id: discovered.customer_id ? discovered.customer_id : "NA",
                        customer_short_name: discovered.customer_short_name ? discovered.customer_short_name: "NA",
                        languagecode: this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
                        allApplications: applications,
                        opsParameter: this.config.getParameterByName('ops') ? this.config.getParameterByName('ops') : '',
                        isOpsUser: this.config.getParameterByName('ops') ? true : false,
                        opsPool: opsPool,                        
                    }));
                    this.discovered = true;
                    this.ssoRedirection = false;
                    await this.data.configureAmplify(clientId, userPool);
                }
                this.common.clearError();
            } else {
                this.discovered = true;
            }
        }
        catch (err) { 
            this.common.clearError();
            this.discovered = true;
        }

        this.common.stopProgress();
    }

    accessRedirect() {
        try {
            var origin = this.config.getParameterByName('origin');
            let application = this.config.getParameterByName('application');
            let loginType = this.config.getParameterByName('loginType');
            if (origin && this.config.isAllowedToRedirect(origin) && this.allowRedirect) {
                this.ssoRedirection = true;
                if(loginType && loginType == 'access') {
                    let redirectUrl = origin + '?logintype=access'
                    location.href = decodeURIComponent(redirectUrl);
                }
                else {
                    location.href = decodeURIComponent(origin);
                }                
            }
            else if(application && application == 'cloudscan') {
                this.sessionReady = true;
                this.router.navigateByUrl('/applications?application='+application);
            }
            else {
                this.sessionReady = true;
                var amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
                let opsParameter = amplifyInfo ? amplifyInfo.opsParameter : null;
                if (opsParameter){
                    this.data.sendData(true);
                    this.router.navigate(['/applications'], { queryParams: { ops: opsParameter } });
                }                    
                else                    
                    this.router.navigate(['/applications']);
            }
        }
        catch (err) {
            this.common.setError(this.tr('access.common.notAllowed'));
        }
    }

    async ngAfterViewInit() {

    }

    private maskPhoneumber(phone) {
        let last4 = phone.substring(phone.length - 4);
        let mask = phone.substring(4, phone.length - 5).replace(/\d/g, "*");
        return mask + last4;
    }

    public async login(username: string, password: string, code: string) {
        console.log("Start login");
        if(password == "" && this.data.password == ""){
            this.passwordRequired = true;
            this.common.stopProgress();
            return;
        }
        username = username.toLowerCase();
        try {
            this.common.clearError();
            this.invalidCode = false;
            this.common.beginProgress();
            //if code already sent
            if (this.user && this.codeSent) {
                if(code == ""){
                    this.codeRequired = true;
                    this.common.stopProgress();
                    return;
                }
                let user = await this.data.confirmSignIn(
                    this.user,
                    code,
                    this.user.challengeName === 'SMS_MFA' ? 'SMS_MFA' : 'SOFTWARE_TOKEN_MFA'
                )
                this.userDetails = user;
                this.config.setCookie("mfa", "enabled", 1);
                let realms = await this.data.postSessionandgetRealms(user, this.sessionCount);
                if(realms == false){
                    this.common.stopProgress();
                    this.closeSessions = true;
                    this.closeSessionsTitle();
                }
                else {
                    this.closeSessions = false;
                    this.setRealmsAndRedirect(realms);
                    return;
                }
            } else { //if code not sent
                const user = await this.data.login(username, password, {
                    user_qp_locale: this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
                    user_qp_origin: this.config.getParameterByName('origin') ? this.config.getParameterByName('origin') : 'NA',
                    user_qp_target: this.config.getParameterByName('target') ? this.config.getParameterByName('target') : 'NA',
                    user_qp_login_type: this.config.getParameterByName('loginType') ? this.config.getParameterByName('loginType') : 'NA',
                    user_qp_ops: this.config.getParameterByName('ops') ? this.config.getParameterByName('ops') : 'NA'
                });
                this.userDetails = user;
                // if 2nd factor required
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                     this.codeText = user.challengeName === 'SMS_MFA' ? this.tr('access.login.codePhoneText') + this.maskPhoneumber(user.challengeParam.CODE_DELIVERY_DESTINATION) : this.tr('access.login.codeAppText');
                    // You need to get the code from the UI inputs
                    // and then trigger the following function with a button click
                    this.codeSent = true;
                    this.common.title = this.tr("access.login.2stepverification");
                    this.user = user;
                    var res = await this.data.getUser(username, user.pool.userPoolId, user.username).toPromise();
                    res = JSON.parse(this.config.decrypt(username, res.user));
                    this.user_mfa_setting_list = res.UserMFASettingList;
                    if (res.UserMFASettingList.length > 1) {
                        this.phoneNumber = res.UserAttributes.find(attr => attr.Name === 'phone_number').Value;
                        this.bothMFAEnabled = true;
                        this.preferredMethod = res.PreferredMfaSetting;
                    }
                    this.common.stopProgress();
                    return;
                } else {
                    let realms = JSON.parse(user.signInUserSession.idToken.payload.realms)
                    console.log('mfa', user.attributes['custom:mfa']);
                    console.log('skip_mfa_enabled', this.skip_mfa_enabled);
                    if (user.attributes['custom:mfa'].toLowerCase() === 'mandatory') {                        
                        this.allowRedirect = false;
                        if (realms.length != 0) 
                            this.config.setCookie('AccessRealm', realms[0].realm_id, 1);
                        localStorage.setItem("amplifyLoginInitiated", "true");
                        this.setupMFA(user.attributes['custom:mfa']);                        
                    }
                    else if (user.attributes['custom:mfa'].toLowerCase() === 'optional') {
                        if (this.skip_mfa_enabled === true) {
                            let fetchedRealms = await this.data.postSessionandgetRealms(user, this.sessionCount);
                            this.setRealmsAndRedirectOrCloseSession(fetchedRealms);
                        } else {
                            this.allowRedirect = false;
                            if (realms.length != 0) 
                                this.config.setCookie('AccessRealm', realms[0].realm_id, 1);
                            localStorage.setItem("amplifyLoginInitiated", "true");
                            this.setupMFA(user.attributes['custom:mfa']);
                        }
                    } 
                    else {
                        let fetchedRealms = await this.data.postSessionandgetRealms(user, this.sessionCount);
                        this.setRealmsAndRedirectOrCloseSession(fetchedRealms);
                    }
                    // let realms = await this.data.postSessionandgetRealms(user, this.sessionCount);
                    // if(realms == false){
                    //     this.common.stopProgress();
                    //     this.closeSessions = true;
                    //     this.closeSessionsTitle();
                    // }
                    // else {
                    //     if (user.attributes['custom:mfa'] == 'optional' || user.attributes['custom:mfa'] == 'mandatory') {
                    //         if (this.skip_mfa_enabled === true) {
                    //             this.setRealmsAndRedirect(realms);
                    //         } else {
                    //             this.allowRedirect = false;
                    //             if (realms.length != 0) 
                    //                 this.config.setCookie('AccessRealm', realms[0].realm_id, 1);
                    //             this.setupMFA(user.attributes['custom:mfa']);
                    //         }
                    //     }
                    //     else {
                    //         this.closeSessions = false;
                    //         this.setRealmsAndRedirect(realms);
                    //     }
                    // }
                }
            }
        } catch (err) {
            if (err.name == 'NotAuthorizedException') {
                this.common.setError(err.message);
            } else if (err.name == 'CodeMismatchException') {
                this.invalidCode = true;
            }else if(err.name == "UserLambdaValidationException" && err.message.includes('PreAuthentication failed')){
                this.common.setError(err.message.substring(err.message.indexOf("error") + 6 , err.message.length))
            }
            else {
                console.log(err);
                this.common.setError(this.tr("access.login.incorrect"));
            }
            this.common.stopProgress();
        }
    }

    async resetMFA(email) {
        this.common.title = this.tr('access.forgot.mfa.title');
        this.reset_mfa_triggered = true;
    }

    async submitMFAReset(email) {
        this.common.loading = true;
        console.log('', this.user_mfa_setting_list);
        this.common.clearError();

        if (localStorage.getItem("deviceKey") && localStorage.getItem("deviceKey").trim() !== "") { // FLOW A BA-561
            let result = await this.data.forgotMFAKnownDevice(email.toLowerCase(), localStorage.getItem("deviceKey"), {
                user_qp_locale: this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
                user_qp_origin: this.config.getParameterByName('origin') ? this.config.getParameterByName('origin') : 'NA',
                user_qp_target: this.config.getParameterByName('target') ? this.config.getParameterByName('target') : 'NA',
                user_mfa_sms_enabled: this.user_mfa_setting_list.includes('SMS_MFA') ? 'true' : 'false',
                user_mfa_totp_enabled: this.user_mfa_setting_list.includes('SOFTWARE_TOKEN_MFA') ? 'true' : 'false'
            });
            //result = 3;
            if (result === 1) {
                this.common.title = this.tr('access.forgot.mfa.action.submitted.title');
                this.reset_mfa_submitted = true;
                this.common.loading = false;
            } else if (result === 2) {
                this.common.title = this.tr("access.forgot.mfa.error.title");
                this.common.setError(this.tr("access.forgot.mfa.error.message"));
                this.common.loading = false;
                this.reset_mfa_device_error = true;
            } else if (result === 3) {
                 this.common.title = this.tr("access.forgot.mfa.toomanyrequests.error.title");
                 this.common.setError(this.tr("access.forgot.mfa.toomanyrequests.error.message1"));
                 this.common.loading = false;
                 this.reset_mfa_too_many_requests_error = true;
            } else {
                this.common.title = this.tr("access.forgot.mfa.error.title");
                this.common.setError(this.tr("access.forgot.mfa.error.unknown.message"));
                this.common.loading = false;
                this.reset_mfa_unknown_error = true;
            }
            /*console.log('', {
                result: result,
                reset_mfa_unknown_error: this.reset_mfa_unknown_error,
                reset_mfa_device_error: this.reset_mfa_device_error,
                reset_mfa_triggered: this.reset_mfa_triggered,
                reset_mfa_submitted: this.reset_mfa_submitted
            })*/
        } else { // TODO FLOW B BA-562
            this.common.setError(this.tr("access.forgot.device.not.recognized.text"));
            this.common.loading = false;
        }

    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async cancelMFAReset() {
        this.reset_mfa_submitted = false;
        this.reset_mfa_triggered = false;
        this.common.clearError();
        this.common.title = this.tr('access.login.title');
    }

    async backToMFAReset() {
        this.reset_mfa_triggered = true;
        this.reset_mfa_submitted = false;
        this.reset_mfa_too_many_requests_error = false;
        this.common.clearError();
        this.common.title = this.tr('access.forgot.mfa.title');
    }

    async changeMFAMethod(email, password){
        if(this.preferredMethod == "SMS_MFA"){
            await this.data.setMFA(this.user.pool.userPoolId, email, this.user.username, {
                enabled: true,
                preferred: false
            }, {
                enabled: true,
                preferred: true
            });
            this.codeText = this.tr('access.login.codeAppText');
            this.preferredMethod = "SOFTWARE_TOKEN_MFA";
        } else if (this.preferredMethod == "SOFTWARE_TOKEN_MFA") {
            await this.data.setMFA(this.user.pool.userPoolId, email, this.user.username, {
                enabled: true,
                preferred: true
            }, {
                enabled: true,
                preferred: false
            });
            this.preferredMethod = "SMS_MFA"
            this.codeText = this.tr('access.login.codePhoneText') + this.maskPhoneumber(this.phoneNumber);
        }
        this.user = await this.data.login(email, password, {
            user_qp_locale: this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
            user_qp_origin: this.config.getParameterByName('origin') ? this.config.getParameterByName('origin') : 'NA',
            user_qp_target: this.config.getParameterByName('target') ? this.config.getParameterByName('target') : 'NA',
            user_qp_login_type: this.config.getParameterByName('loginType') ? this.config.getParameterByName('loginType') : 'NA',
            user_qp_ops: this.config.getParameterByName('ops') ? this.config.getParameterByName('ops') : 'NA'
        });
    }

    setupMFA(mfa) {
        if(mfa.toLowerCase() == 'mandatory') {
            localStorage.setItem("mfa", 'mandatory');
        }
        this.data.updateEntryPoint("login");
        let url = '/setupmfa';
        url += '?redirect=true&origin=' + encodeURIComponent(this.config.getParameterByName('origin')) + '&entrypoint=login';
        if (this.config.getParameterByName('target')) {
            url += '&target=' + encodeURIComponent(this.config.getParameterByName('target'));
        }
        if(this.config.getParameterByName('languagecode')) {
            url += '&languagecode=' + encodeURIComponent(this.config.getParameterByName('languagecode'));
        }
        this.router.navigateByUrl(url);
        this.common.stopProgress();
    }

    setRealmsAndRedirectOrCloseSession(fetchedRealms) {
        if(fetchedRealms == false){
            this.common.stopProgress();
            this.closeSessions = true;
        }else {
            this.closeSessions = false;
            this.setRealmsAndRedirect(fetchedRealms);
        }
    }

    setRealmsAndRedirect(realms) {        
        // select first and redirect
        if (realms.length != 0) {
            this.config.setCookie('AccessRealm', realms[0].realm_id, 1);
            this.accessRedirect();
        } else {
            this.accessRedirect();
        }
        this.common.stopProgress();
    }


    async formButton(username: string, password: string, code: string) {
        if (!this.discovered)
            this.discover(username);
        else
            this.login(username, password, code);

        return false;
    }

    async closeActiveSessions(username: string, password: string) {

        var result = await this.data.closeActiveSessions(this.userDetails.signInUserSession.accessToken.jwtToken);
        if(result.body){

            let realms = await this.data.postSessionandgetRealms(this.userDetails, this.sessionCount);
            if(realms == false){
                this.common.stopProgress();
                this.closeSessions = true;
                this.closeSessionsTitle();
            }
            else {
                this.closeSessions = false;
                this.setRealmsAndRedirect(realms);
            }
        }
        else{
            this.router.navigate(['/logout']);
        };
    }

    buttonText() {
        if ((!this.discovered || this.codeSent) && !this.closeSessions)
            return this.tr('access.login.next');
        else if(this.closeSessions)
            return this.tr("access.closeSessions.closeActiveSessions");
        else
            return this.tr('access.login.login');
    }

    tr(text: string) {
        return this.localization.translate(text);
    }

    closeSessionsTitle() {
        this.common.title = this.tr("access.closeSessions.title");
    }
}
