import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    //private messageSource = new BehaviorSubject('');
    //currentMessage = this.messageSource.asObservable();

    constructor() { }

    error = undefined;
    loading = false;
    pageLoading = false;
    title = undefined;
    isTextFieldType = false;

    isTextFieldTypeOldPassword = false;
    isTextFieldTypeNewPassword = false;

    beginPageLoading() {
        this.pageLoading = true;
    }

    stopPageLoading() {
        this.pageLoading = false;
    }

    beginProgress() {
        this.loading = true;
    }

    stopProgress() {
        this.loading = false;
    }

    setError(text: string) {
        this.error = text;
        // this.messageSource.next(text);
    }

    clearError() {
        this.error = undefined;
    }

    togglePasswordFieldType() {
        this.isTextFieldType = !this.isTextFieldType;
        this.isTextFieldTypeOldPassword = false;
        this.isTextFieldTypeNewPassword = false;
    }

    togglePasswordFieldTypeById(type) {
        if (type === 'old_password') {
            this.isTextFieldTypeOldPassword = !this.isTextFieldTypeOldPassword;
        } else if (type === 'new_password') {
            this.isTextFieldTypeNewPassword = !this.isTextFieldTypeNewPassword;
        }
    }
}
