import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import Auth from '@aws-amplify/auth';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CognitoIdToken, CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";

const CHANGE_PASSWORD_EVENT = "CHANGE_PASSWORD";
const MFA_CHANGES_EVENT = "MFA_CHANGES";
const DEFAULT_LOCALE = "en-US";
const SET_MFA_TOKEN = "X53PK3neuJkaFKBsLDfqSd3NWlpOiw7B";

@Injectable({
    providedIn: 'root'
})
export class DataService {
    accesstoken = ""
    samlUserDetails = null;
    userPoolId = "";
    loginAction = "";
    private routerMessage = new Subject<any>();
    reAuthenticationAction = "";
    private msgSource = new BehaviorSubject("");
    msg = this.msgSource.asObservable();
    updateMsg(msg) {
        this.msgSource.next(msg);
    }

    private entryPointSource = new BehaviorSubject("");
    entryPoint = this.entryPointSource.asObservable();
    updateEntryPoint(entryPoint) {
        this.entryPointSource.next(entryPoint);
    }

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    auth = undefined;
    username = undefined;
    password = undefined;
    resetSuccessful = false;

    async getWellKnownKeys(url) {
        let result = await this.http.get(url).toPromise();
        return result;
    }

    sendData(message: any) {
        this.routerMessage.next(message);
    }
    

    getData(): Observable<any> {
        return this.routerMessage.asObservable();
    }

    async getToken(url: string, userPoolId: string, clientId: string, sessionCount: number, code: string, isOpsUser: boolean = false, opsPool: string) {
        let body = new URLSearchParams();
        body.set('grant_type', "authorization_code")
        body.set('code', code)
        body.set('redirect_uri', this.getDomain() + "/callout")
        body.set('client_id', clientId)
        let user = await this.http.post(url, body.toString(), {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        }).toPromise();

        this.getOrSetDeviceKey(userPoolId);

        this.accesstoken = user["access_token"];
        let activeSessions = await this.checkActiveSessions(user["access_token"], sessionCount);

        this.samlUserDetails = user;
        this.userPoolId = userPoolId;

        // body variable
        if(activeSessions.body){            
            return this.postSessionForSamlUser(isOpsUser, opsPool);
        }
        else {
            return false;
        }
    }

    getOrSetDeviceKey(userPoolId){
        let storedDeviceKey = localStorage.getItem('deviceKey');
        var deviceKey = "";
        if(storedDeviceKey) {
            deviceKey =  storedDeviceKey;
        }
        else {
            deviceKey =  userPoolId.split('_')[0] +'_'+this.createGuid();
            localStorage.setItem("deviceKey",deviceKey);
        }
        return deviceKey;
    }

    async postSessionForSamlUser(isOpsUser: boolean = false, opsPool: string){

        //console.log(decode(user["id_token"]))
        await this.sessionPost(this.userPoolId, this.samlUserDetails["access_token"], this.samlUserDetails["refresh_token"], this.samlUserDetails["id_token"], localStorage.getItem('deviceKey'), isOpsUser);
        var decoded = new JwtHelperService().decodeToken( this.samlUserDetails["id_token"]);
        if(!!isOpsUser && (opsPool.toUpperCase() == "BASWARE" || opsPool.toUpperCase() == "PARTNER")){                   
            return true;
        }            
        else{
            return JSON.parse(decoded.realms);
        }
            
    }

    private getApiDomain() {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            let url = new URL(window.location.href);
            return url.searchParams.get("domain");
        }
        else {
            return location.protocol + '//api.' + location.hostname + (location.port ? ':' + location.port : '');
        }
    }

    public getDomain() {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            var url = new URL(window.location.href);
            return url.searchParams.get("domain");
        }
        else {
            return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        }
    }

    async reauthSessionPost(accessToken:string) {
        let target = this.getApiDomain() + '/v1/session/re-auth';
        let headers = new HttpHeaders({
            'access_token': accessToken,
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });
        await this.http.post<any>(target, null, { withCredentials: true, headers: headers }).toPromise();
    }

    async isReAuthSessionValid() {
        let target = this.getApiDomain() + '/v1/session/re-auth';

        let headers = new HttpHeaders({
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });

        try {
            let res = await this.http.get<any>(target, { withCredentials: true, headers: headers}).toPromise();
            return true;
        }
        catch (err) {
            // TODO remove
            return false;
        }
    }

    async sessionPost(userPoolId:string,  accessToken:string, refreshToken:string, idToken:string, deviceKey:string, isOpsUser: boolean = false) {
        var target = this.getApiDomain() + '/v1/session';
        const amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
        const locale = amplifyInfo.languagecode;
        var payload = JSON.stringify({
            accessToken: accessToken,
            refreshToken: refreshToken,
            idToken: idToken,
            userPoolId: userPoolId,
            deviceKey: deviceKey,
            isOpsUser: isOpsUser,
            userAgent: navigator.userAgent,
            locale: locale
        })
        await this.http.post<any>(target, payload, { withCredentials: true }).toPromise();
    }

    async deleteSession() {
        let target = this.getApiDomain() + '/v1/session';

        if (localStorage.getItem('amplifyInfo')) {
            let amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));

            if (amplifyInfo.isOpsUser && amplifyInfo.isOpsUser === true) {
                target += "?isOpsUser=true"
            }
        }

        await this.http.delete<any>(target, { withCredentials: true }).toPromise();
    }

    async skipMFAForUser(user, skip = false) {
        let target = this.getApiDomain() + '/v1/user/skip-mfa';
        let session:CognitoUserSession = await user.signInUserSession;
        let idToken:CognitoIdToken = await session.getIdToken();
        let userId:string = idToken.payload["custom:user_id"];
        let accessToken:string = await session.getAccessToken().getJwtToken();
        let headers = new HttpHeaders({
            'access_token': accessToken,
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });
        let payload = JSON.stringify({
            user_id: userId,
            skip_mfa: skip
        });
        await this.http.put<any>(target, payload, { headers: headers }).toPromise();
    }

    async sendNotification(user, locale: string, event_type: string) {
        let target = this.getApiDomain() + '/v1/notify';
        let session:CognitoUserSession = await user.signInUserSession;
        let idToken:CognitoIdToken = await session.getIdToken();        
        let accessToken:string = await session.getAccessToken().getJwtToken();
        let payload = {
            id_token: idToken.getJwtToken(),
            event_type: event_type
        };
        let headers = new HttpHeaders({
            'access_token': accessToken,
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });        
        await this.http.post<any>(target, payload, { headers: headers }).toPromise();
    }

    async forgotMFAKnownDevice(email, deviceKey, queryParamsMetadata): Promise<number> {

        // user is already discovered at this stage and getting amplifyInfo null is not going to be condition anyway
        let amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));

        return await Auth.forgotPassword(email, {
            device_key: deviceKey,
            email: email,
            client_id: amplifyInfo.client_id,
            flow: "MFA_RESET_KNOWN_DEVICE",
            ...this.getCustomerIdAndShortName(),
            ...queryParamsMetadata
        }).then(r => {
            console.info("", r);
            return 1;
        }).catch(e => {
            console.log("-- ",e);
            if (e.message.indexOf('UNKNOWN DEVICE KEY') !== -1) {
                return 2;
            }
            else if(e.name == "TooManyRequestsException" || e.name == "LimitExceededException") {
                return 3;
            }
            else {
                // may use another value for unknown errors??
                return 0;
            }
        });
    }

    async login(username: string, password: string, metadata = {}) {

        try {

            let logging_metadata = {
                ...this.getCustomerIdAndShortName(),
                ...metadata,
                device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : 'NA'
            }

            const user = await Auth.signIn(username, password, {
                ...logging_metadata
            });

            /*const user = await Auth.signIn({ username, password, validationData : logging_metadata}, password, {
                ...logging_metadata
            });*/

            return user;
        } catch (e) {
            throw e;
        }
    }

    async confirmSignIn(user, code, challengeName){
        let signedInUser = await Auth.confirmSignIn(
            user,   // Return object from Auth.signIn()
            code,   // Confirmation code
            challengeName,
            {
                ...this.getCustomerIdAndShortName(),
                device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey'): "NA"
            }
        );
        return signedInUser;
    }

    async isLoggedIn(locale = '', origin= '', target = '', loginType = '', ops = '') {

        const headers = new HttpHeaders({
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : 'NA',
            'locale': locale,
            'origin_url': origin,
            'target_url': target,
            'login_type': loginType,
            'ops': ops,
        });

        const httpOptions = { withCredentials: true, headers };

        const endpoint = this.getApiDomain() + `/v1/status`;
        try {
            const res = await this.http.get<any>(endpoint, httpOptions).toPromise();
            return true;
        } catch (err) {
            return false;
        }
    }

    async checkActiveSessions(accessToken: string, sessionCount: number) {

        let headers = new HttpHeaders({
            'access_token': accessToken,
            'session_count': sessionCount.toString(),
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });

        const httpOptions = {
            headers: headers
        };

        let target = this.getApiDomain() + '/v1/session/activesessioncount';

        return this.http.get<any>(target, httpOptions).toPromise();
    }

    async closeActiveSessions(accessToken: string) {

        let target = this.getApiDomain() + '/v1/closeactivesessions';

        let headers = new HttpHeaders({
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });

        var payload = JSON.stringify({ accessToken: accessToken })
        return this.http.post<any>(target, payload, { headers: headers }).toPromise();
    }

    async getCurrentUser() {
        var user = await Auth.currentAuthenticatedUser();
        return user;
    }

    async getIdTokenFromSession() {
        var user = await Auth.currentAuthenticatedUser();
        var userSession = await Auth.userSession(user);
        //var userSession = await Auth.currentSession();
        const idToken = userSession.getIdToken().getJwtToken();
        return idToken;
    }

    async getIdTokenFromSession2()  {
        var userSession = await Auth.currentSession();
        const idToken = userSession.getIdToken().getJwtToken();
        return idToken;
    }

    getUser(username, userPoolId) {
        let headers = new HttpHeaders({
            'email': username,
            'userpool': userPoolId,
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });

        const httpOptions = {
            headers: headers
        };
        let target = this.getApiDomain() + '/v1/getuser';
        return this.http.get<any>(target, httpOptions);
    }

    async setUserPassword(userName, password, userPoolId) {
        let headers = new HttpHeaders({
            'userpool': userPoolId,
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });
        
        let payload = JSON.stringify({
            'email': userName,
            'password': password
        })
        let target = this.getApiDomain() + '/v1/getuser';
        await this.http.post<any>(target, payload, { headers:headers}).toPromise();
    }

    async setMFA(userpool, email, sms, totp, requester = 'unknown', flow = ''){
        var target = this.getApiDomain() + '/v1/setmfa';
        var payload = JSON.stringify({
            userpool: userpool,
            email: email,
            sms: {
                enabled: sms.enabled,
                preferred: sms.preferred
            },
            totp: {
                enabled: totp.enabled,
                preferred: totp.preferred
            },
            requester: requester
        });
        console.log(payload)

        let headers = new HttpHeaders({
            'access_token': SET_MFA_TOKEN,
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA",
            flow: flow
        });

        await this.http.post<any>(target, payload, { headers:headers}).toPromise();
    }

    async postSessionandgetRealms(user, sessionCount: number) {
        let activeSessions = await this.checkActiveSessions(user.signInUserSession.accessToken.jwtToken, sessionCount);

        if(activeSessions.body){
            let userPoolId = user.pool.userPoolId;
            let accessToken = user.signInUserSession.accessToken.jwtToken;
            let refreshToken = user.signInUserSession.refreshToken.token;
            let idToken = user.signInUserSession.idToken.jwtToken;
            let realms = user.signInUserSession.idToken.payload.realms;

            if (user.preferredMFA === "NOMFA" && (user.attributes && user.attributes['custom:mfa'] && (user.attributes['custom:mfa'] == 'optional' || user.attributes['custom:mfa'] == 'mandatory'))) {
                await this.sessionPost(userPoolId, accessToken, refreshToken, idToken, this.getOrSetDeviceKey(userPoolId));
            } else {
                // parallel calls to create the normal session plus the new Re-Auth Session Cookie Creation API
                await Promise.all([this.sessionPost(userPoolId, accessToken, refreshToken, idToken, this.getOrSetDeviceKey(userPoolId)),
                    this.reauthSessionPost(user.signInUserSession.accessToken.jwtToken)]);
            }

            // write idToken
            localStorage.setItem("idToken", idToken);

            return JSON.parse(realms);
        }
        else {
            return false;
        }
    }

    async getRealms(user) {
        let realms = user.signInUserSession.idToken.payload.realms;
        return JSON.parse(realms);
    }

    async getAccessToken(user) {
        let accessToken = user.signInUserSession.accessToken.jwtToken;
        return accessToken;
    }

    configureAmplify(client_id: string, user_pool: string) {
        if (typeof this.auth == "undefined") {
            Auth.configure({
                region: user_pool.split('_')[0],
                userPoolId: user_pool,
                userPoolWebClientId: client_id
            });
            this.auth = Auth;
        }
        return this.auth;
    }

    forgotPassword(email: string) {
        let headers = new HttpHeaders({
            'email': email,
        });

        const httpOptions = {
            headers: headers
        };

        let target = this.getApiDomain() + '/v1/forgotpassword';

        return this.http.get<any>(target, httpOptions);
    }

    discoverUserPool(customerid, userpool){
        let headers = new HttpHeaders({
            'customerid': customerid.toUpperCase(),
            'userpool': userpool.toUpperCase()
        });

        const httpOptions = {
            headers: headers
        };

        let target = this.getApiDomain() + '/v1/discoveruserpool';

        return this.http.get<any>(target, httpOptions);
    }

    discover(email: string, customerid: string = '', flow = '', locale = '', origin = '', target = '', loginType = '', ops = '') {
        const headers = new HttpHeaders({
            'email': email,
            'customerid': customerid.toUpperCase(),
            'flow': flow,
            'locale': locale,
            'origin_url': origin,
            'target_url': target,
            'login_type': loginType,
            'ops': ops,
            'device_key': localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : 'NA'
        });

        const httpOptions = {
            headers: headers
        };

        const endpoint = this.getApiDomain() + '/v1/signin';

        return this.http.get<any>(endpoint, httpOptions);
    }

    async getAllTenants(username: string, region: string) {
        const headers = new HttpHeaders({
            'username': username,
            'region': region
        });

        const httpOptions = {
            headers: headers
        };

        const endpoint = this.getApiDomain() + '/v1/get_alltenants';

        return this.http.get<any>(endpoint, httpOptions).toPromise();
    }

    async changePassword(user: CognitoUser, locale:string, oldpw: string, newpw: string) {
        // Change password
        await Auth.changePassword(user, oldpw, newpw, {
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey'): "NA"
        });
        await this.sendNotification(user, locale, CHANGE_PASSWORD_EVENT);
    }

    async changePasswordEmailAfterReset(email: string, locale:string, userPoolId: string) {

        let target = this.getApiDomain() + '/v1/notify';        
        let payload = {
            email: email,
            event_type: CHANGE_PASSWORD_EVENT,
            locale: locale,
            user_pool_id: userPoolId
        };
        let headers = new HttpHeaders({
            'access_token': SET_MFA_TOKEN,
            ...this.getCustomerIdAndShortName(),
            device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey') : "NA"
        });
        
        await this.http.post<any>(target, payload, { headers: headers }).toPromise();        
    }    

    // if fresh browser then, the values will be NA for both
    getCustomerIdAndShortName(): {customer_id:string, customer_short_name:string} {
        let amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));

        if (amplifyInfo) {
            return {
                customer_id: amplifyInfo.customer_id ? amplifyInfo.customer_id : "NA",
                customer_short_name: amplifyInfo.customer_short_name ? amplifyInfo.customer_short_name: "NA"
            }
        }

        return {
            customer_id: "NA",
            customer_short_name: "NA"
        }
    }

    createGuid(){
        function S4() {
            return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }

    async validateTokenAndCreateTenantToken(tenantCode:string, customerID:string, idToken:string, allTenants:string, scope:string) { 

        var target = this.getApiDomain() + '/v1/tenanttoken';  

        var payload = JSON.stringify({
            tenantCode: tenantCode ? tenantCode : 'NA',
            customerID: customerID ? customerID.toUpperCase() : 'NA',
            idToken: idToken,
            allTenants: allTenants,
            scope: scope
        })

        try {
            await this.http.post<any>(target, payload, { withCredentials: true }).toPromise();
            return true;
        }
        catch(e) {
            console.log("TENANT-TOKEN")
            console.log(e)
            return false;
        }         
    }
}

// Dummy storage to prevent amplify from persisting auth info in browser
class DummyStorage {
    static setItem(key: string, value: string): string {
        return null;
    }
    static getItem(key: string): string {
        return null;
    }
    static removeItem(key: string): void { }
    static clear(): void { };
}
