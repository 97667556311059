import {ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {DataService} from "../data.service";
import {ConfigService} from "../config.service";
import {LocalizationService} from "../localization.service";
import {CommonService} from "../common.service";
import {JwtHelperService} from '@auth0/angular-jwt';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import { Router, ActivatedRoute } from '@angular/router';

enum Application {
    PURCHASETOPAY = 'PurchaseToPay',
    REPORTING = 'Reporting',
    SUPPLIERPORTAL = 'SupplierPortal',
    BASWAREADMIN = 'BaswareAdmin',
    STRATEGICSOURCING = 'StrategicSourcing',
    SUPPLIERMANAGEMENT = 'SupplierManagement',
    INTERNALMONITORING = 'InternalMonitoring',
    SMARTPDF = 'SmartPDF',
    ONP = 'Onp',
    BASWARECONFIG = 'BaswareConfig'
}

interface ServiceData{
    name:string,
    url:string
}

enum serviceScope {
    ANY = "any",
    RESTRICTED = "restricted"
}

interface TableData {
    bw_customer_id: string,
    tenant_code: string,
    customer_codes: [],
    url: string
}

@Component({
    selector: 'applications',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.scss']
})


export class ApplicationsComponent implements OnInit {

    constructor(
        private config: ConfigService,
        private data: DataService,
        private localization: LocalizationService,
        public common: CommonService,
        private route: ActivatedRoute,
        private cdRef : ChangeDetectorRef,
        private router: Router
    ) {
    }

    dataSourceTenant = [];

    
    dataSourceService = new MatTableDataSource <ServiceData> ();
    columnsToDisplayService = ['service', 'actions'];
    
    length = 100;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    
    maxWidthStyle = "480px";
    
    applications = false;
    tenantsVisible = false;
    hideTitle = false;
    isCloudScanApplication = false;
    noApplications = false;
    noApplicationsError= false;
    userApps:ServiceData[]= [];
    //userPermissions= [];
    userPermissionsScope= [];
    allTenants =[];
    tenantsList=[];
    cloudScanMessage = '';
    isGraviton = false;
    opsTenantsError = false;
    noTenantAccessErrorMessage1 = '';

    @ViewChild('paginator1', {
        static: false
    }) paginator1: MatPaginator;


    async ngOnInit() {
        
        this.common.title = this.tr('access.callout.redirect.title'); 
        await this.checkIfUserIsLoggedIn();

        var amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
        if(amplifyInfo) {
            if (amplifyInfo.isOpsUser && amplifyInfo.opsPool.toUpperCase() == "BASWARE") {
                this.isGraviton = true;
                this.data.sendData(true);
                let origin = amplifyInfo.origin;
                //if tenant ,set in new token     
                let idToken = "";
                if(this.data.samlUserDetails != null && this.data.samlUserDetails["id_token"] != null){
                   localStorage.setItem("idToken", this.data.samlUserDetails["id_token"]);
                   idToken = this.data.samlUserDetails["id_token"];
                }
               
                if(idToken == ""){
                   idToken = localStorage.getItem('idToken') != null ?  localStorage.getItem('idToken') : '';
                }

                if (!this.cdRef['destroyed']) {
                    this.cdRef.detectChanges();
                }
                var decoded = new JwtHelperService().decodeToken(idToken);
                //get all tenants
                var cmosAllTenants = await this.data.getAllTenants(decoded.loginAccount , decoded.region);
                if(cmosAllTenants) {
                    localStorage.setItem("allTenants", cmosAllTenants.alltenants);
                }
                
                if(decoded.permissions && decoded.permissions != '') {
                    const permissions = decoded.permissions.split(",");
                    let userPermScope = [];
                    permissions.forEach((element) => {
                        //this.userPermissions.push(element);
                        this.applications = true;
                        let appName;                            
                        switch (element.split(":")[0].split(".")[1].toString().toLowerCase()) {
                            case "alusta":
                                appName = Application.PURCHASETOPAY.toString();
                                break;
                            case "reporting":
                                appName = Application.REPORTING.toString();
                                break;
                            case "bwadmin":
                                appName = Application.BASWAREADMIN.toString();
                                break;
                            case "portal":
                                appName = Application.SUPPLIERPORTAL.toString();
                                break;
                            case "monitoring":
                                appName = Application.INTERNALMONITORING.toString();
                                break;
                            case "smartpdf":
                                appName = Application.SMARTPDF.toString();
                                break;
                            case "onp":
                                appName = Application.ONP.toString();
                                break;
                            case "bwconfig":
                                appName = Application.BASWARECONFIG.toString();
                                break;
                            default:
                                appName = element.split(":")[0].split(".")[1];
                        }
                        if(appName !== "resetmfa") { 
                            userPermScope.push({"permission": appName, "scope": element.split(":")[1]});
                            let allApplications = amplifyInfo.allApplications
                            let applicationUrl = "";
                            if (allApplications[appName.toString()]) {
                                applicationUrl = allApplications[appName.toString()].url ? allApplications[appName.toString()].url : '';
                            }                            
                            //filter duplicate applications
                            if (Object.values(this.userApps).filter(app => app.name == appName).length == 0)
                                this.userApps.push({name: appName, url: applicationUrl})
                        }                        
                    })
                    //filter duplicate applications
                    this.userPermissionsScope = this.getFilteredUserApplications(userPermScope);
                }

                if(amplifyInfo.tenant) {
                    this.opsTenantsError = false;
                    let scope = "restricted";                    
                    if(amplifyInfo.application && amplifyInfo.application !== "" && amplifyInfo.application !== null) {                        
                        let filteredPermission = this.userPermissionsScope.find(permission => permission.permission.toLowerCase() === amplifyInfo.application.toLowerCase());
                        scope = filteredPermission ? filteredPermission.scope : "restricted";
                    }
                    //validate existing token and create new token
                    let result = await this.data.validateTokenAndCreateTenantToken(amplifyInfo.tenant, amplifyInfo.customerID ? amplifyInfo.customerID.toUpperCase() : "NA", idToken, cmosAllTenants.alltenants, scope);
                    //if not in tenants show error page
                    if(result == false) {
                        this.tenantsVisible = true;
                        this.opsTenantsError = true;
                        this.hideTitle = true;
                        this.common.title = this.tr('access.login.applications.notenantaccesserror.title');
                        this.noTenantAccessErrorMessage1 = this.tr('access.login.applications.notenantaccesserror.message1') + ' ' + amplifyInfo.tenant;
                        this.maxWidthStyle = "480px";   
                    }    
                    else if(result == true && origin && this.config.isAllowedToRedirect(origin)) {
                        location.href = decodeURIComponent(origin);
                    }
                } else {
                    //this.allTenants.push(...JSON.parse(decoded.alltenants));                    
                    this.allTenants.push(...JSON.parse(cmosAllTenants.alltenants));
                    this.tenantsList.push(...JSON.parse(decoded.restrictedTenants));                    
                    if(Object.keys(this.userApps).length > 0) {
                        this.common.title = this.tr('access.login.applications.selectService.title');
                        this.dataSourceService.data = [];
                        this.dataSourceService.data = this.userApps;
                        this.dataSourceService.data.sort((a, b) => {
                            return a.name.localeCompare(b.name);                            
                        });
                        setTimeout(() => {
                            this.dataSourceService.data = [...this.dataSourceService.data];
                        });                        
                    } else {
                        this.noApplicationsError = true;
                        this.hideTitle = true;
                        this.common.title = this.tr('access.login.applications.notenantaccesserror.title');
                    }
                }
            }
            else if (amplifyInfo.isOpsUser && amplifyInfo.opsPool.toUpperCase() == "PARTNER") {
                this.isGraviton = true;
                this.data.sendData(true);
                //if tenant ,set in new token     
                let idToken = "";
                if(this.data.samlUserDetails != null && this.data.samlUserDetails["id_token"] != null){
                    localStorage.setItem("idToken", this.data.samlUserDetails["id_token"]);
                    idToken = this.data.samlUserDetails["id_token"];
                }
                
                if(idToken == ""){
                    idToken = localStorage.getItem('idToken') != null ?  localStorage.getItem('idToken') : '';
                }

                if (!this.cdRef['destroyed']) {
                    this.cdRef.detectChanges();
                }
                var decoded = new JwtHelperService().decodeToken(idToken);
                
                if(decoded && decoded.permissions && decoded.permissions != '') {
                    const permissions = decoded.permissions.split(",");
                    let userPermScope = [];
                    permissions.forEach((element) => {                        
                        this.applications = true;
                        let appName;                            
                        switch (element.split(":")[0].split(".")[1].toString().toLowerCase()) {                            
                            case "bwconfig":
                                appName = Application.BASWARECONFIG.toString();
                                break;
                            case "bwadmin":
                                appName = Application.BASWAREADMIN.toString();
                                break;
                            case "smartpdf":
                                appName = Application.SMARTPDF.toString();
                                break;
                            default:
                                appName = element.split(":")[0].split(".")[1];
                        } 
                        if(appName !== "resetmfa") {                       
                            userPermScope.push({"permission": appName, "scope": "restricted"});
                            let allApplications = amplifyInfo.allApplications
                            let applicationUrl = "";
                            if (allApplications[appName.toString()]) {
                                applicationUrl = allApplications[appName.toString()].url ? allApplications[appName.toString()].url : '';
                            }                            
                            //filter duplicate applications
                            if (Object.values(this.userApps).filter(app => app.name == appName).length == 0)
                                this.userApps.push({name: appName, url: applicationUrl})
                        }
                    })
                    //filter duplicate applications
                    this.userPermissionsScope = this.getFilteredUserApplications(userPermScope);
                    this.tenantsList.push(...JSON.parse(decoded.restrictedTenants));                    
                    if(Object.keys(this.userApps).length > 0) {
                        this.common.title = this.tr('access.login.applications.selectService.title');
                        this.dataSourceService.data = [];
                        this.dataSourceService.data = this.userApps;
                        this.dataSourceService.data.sort((a, b) => {
                            return a.name.localeCompare(b.name);                            
                        });
                        setTimeout(() => {
                            this.dataSourceService.data = [...this.dataSourceService.data];
                        });                        
                    } else {
                        this.noApplicationsError = true;
                        this.hideTitle = true;
                        this.common.title = this.tr('access.login.applications.notenantaccesserror.title');
                    }
                } else {
                    this.noApplicationsError = true;
                    this.hideTitle = true;
                    this.common.title = this.tr('access.login.applications.notenantaccesserror.title');
                }
            }
            else {
                
                if(amplifyInfo.allApplications && Object.keys(amplifyInfo.allApplications).length > 0) {
                    
                    let idToken = "";
                    if(this.data.samlUserDetails != null && this.data.samlUserDetails["id_token"] != null){
                    localStorage.setItem("idToken", this.data.samlUserDetails["id_token"]);
                    idToken = this.data.samlUserDetails["id_token"];
                    }
                
                    if(idToken == ""){
                    idToken = localStorage.getItem('idToken') != null ?  localStorage.getItem('idToken') : '';
                    }
                    let decoded = new JwtHelperService().decodeToken(idToken);
                    if(decoded.applications) {
                        for (let [key, value] of Object.entries(amplifyInfo.allApplications)) {
                        if (decoded.applications.includes(key)) {
                            this.userApps.push({name: key , url: value['url']})
                        }
                    }
                    }
                }

                if (this.config.getParameterByName("application") && this.config.getParameterByName("application") == "cloudscan") {
                    if(this.config.getParameterByName("flow") && this.config.getParameterByName("flow") == "fp") {
                        this.common.title = this.tr('access.login.applications.noservice.forgotPasswordTitle');
                        this.cloudScanMessage =  this.tr('access.login.applications.cloudscan.message2');
                        this.isCloudScanApplication = true;
                    }                
                    else {
                        this.common.title = this.tr('access.login.applications.noWebService.title');
                        this.noApplications = true;
                    }       
                                
                    this.common.clearError();
                }
                else {
                   this.redirectAccordingTOService();
                }
            }            
        }        
        else {
            this.common.title = this.tr('access.login.applications.noWebService.title');
            this.noApplications = true;
        }      
    }

    private getFilteredUserApplications(userPermScope=[]) {

        let userPermissionsWithScope = [];
        if(Object.keys(userPermScope).length > 0) {
            userPermScope.forEach(elm =>
            {
                let permissionLength = Object.values(userPermScope).filter(app => app.permission == elm.permission).length; 
                if(permissionLength > 1) {
                    let anyScopeLength = Object.values(userPermScope).filter(app => app.permission == elm.permission && app.scope ==serviceScope.ANY).length;
                    let restrictedScopeLength = Object.values(userPermScope).filter(app => app.permission == elm.permission && app.scope ==serviceScope.RESTRICTED).length;
                    let scope;

                    if(permissionLength == anyScopeLength)
                        scope = serviceScope.ANY.toString()
                    else if(permissionLength == restrictedScopeLength)
                        scope = serviceScope.RESTRICTED.toString()
                    else 
                        scope = serviceScope.ANY.toString()

                    if (Object.values(userPermissionsWithScope).filter(a=>a.permission == elm.permission).length == 0)
                        userPermissionsWithScope.push({"permission":elm.permission, "scope":scope}) 
                }
                else {
                    userPermissionsWithScope.push({"permission":elm.permission, "scope":elm.scope})
                }                
            }) 
        }
        return userPermissionsWithScope;
    }
     
    private displayErrorMessage(value){
        this.opsTenantsError = true;
        this.hideTitle = true;
        this.common.title = this.tr('access.login.applications.notenantaccesserror.title');
        this.noTenantAccessErrorMessage1 = this.tr('access.login.applications.notenantaccesserror.message1') + ' ' + value.tenant_code;
        this.maxWidthStyle = "480px";
    }

    private redirectAccordingTOService() {
        if(Object.keys(this.userApps).length > 0) {
            if(this.userApps.length == 1) {
                if(this.userApps[0].url && this.userApps[0].url != '')
                    location.href = this.userApps[0].url;
                else {
                    this.common.title = this.tr('access.login.applications.noWebService.title');
                    this.noApplications = true;
                }
            }
            else {
                this.applications = true;
                this.common.title = this.tr('access.login.applications.selectService.title');
            }
        }
        else {
            this.common.title = this.tr('access.login.applications.noWebService.title');
            this.noApplications = true;
            this.common.clearError();
        }
    }
    
    private displayTenantOrRedirect(app : {name: string, url: string}){
        let tenantsFound : boolean = false;
        if(app.name.toString().toLowerCase() == Application.INTERNALMONITORING.toString().toLowerCase() ||
        app.name.toString().toLowerCase() == Application.ONP.toString().toLowerCase() ||
        app.name.toString().toLowerCase() == Application.BASWARECONFIG.toString().toLowerCase()) {
            location.href = app.url;
        }
        else {
            this.userPermissionsScope.forEach((el) =>{
                if(el.permission.toString().toLocaleLowerCase() == app.name.toString().toLowerCase()){
                    let scope = serviceScope.ANY.toString();
                    let allApplicatins = JSON.parse(localStorage.getItem('amplifyInfo')).allApplications
                    let applicationUrl = allApplicatins[app.name.toString()].url
                    this.dataSourceTenant =[];
                    if(el.scope.toString().toLowerCase() == serviceScope.ANY.toString()){
                        this.dataSourceTenant.push(...this.allTenants);
                        scope = serviceScope.ANY.toString();
                    }else {
                        this.dataSourceTenant.push(...this.tenantsList);
                        scope = serviceScope.RESTRICTED.toString();
                    }
    
                    this.dataSourceTenant.forEach(element =>{
                        element.url = applicationUrl;
                        element.scope = scope;
                    })

                    this.dataSourceTenant.sort((a, b) => {                        
                        return a.tenant_code.localeCompare(b.tenant_code); 
                    });
                    
                    if(this.dataSourceTenant.length > 0) {
                        this.common.title = "Tenant";
                        this.applications = false;
                        this.tenantsVisible = true;
                        this.hideTitle = true;
                        this.maxWidthStyle = "960px";
                        tenantsFound = true;
                    }
                }
            })
    
            if(!tenantsFound) {
                location.href = app.url;
            }
        }        
    }

    private formatLongAndShortName(value : string) : string{
        return value.toString().replace(/,/g, ', ');
    }

    changeDisplayName(name) {
        if(name == Application.PURCHASETOPAY.toString())
            return "Basware Purchase to Pay"
        else if(name == Application.REPORTING.toString())
            return "Basware Reporting"
        else if(name == Application.SUPPLIERPORTAL.toString())
            return "Basware Network"
        else if(name == Application.BASWAREADMIN.toString())
            return "Basware Admin"
        else if(name == Application.STRATEGICSOURCING.toString())
            return "Strategic Sourcing"
        else if(name == Application.SUPPLIERMANAGEMENT.toString())
            return "Supplier Management"
        else if(name == Application.INTERNALMONITORING.toString())
            return "Basware Monitoring" 
        else if(name == Application.SMARTPDF.toString())
            return "Basware SmartPDF"
        else if(name == Application.ONP.toString())
            return "Basware Portal"
        else if(name == Application.BASWARECONFIG.toString())
            return "Basware Config"
        else
            return name
    }  
    
    async checkIfUserIsLoggedIn() {
        try{
            let opsParameter = this.config.getParameterByName('ops');
            let loggedIn = await this.data.isLoggedIn(
                'en-US', 'NA', 'NA', 'NA',
                opsParameter ? opsParameter : 'NA'
            );            
            if (!loggedIn) {                               
                if (opsParameter) {                    
                    this.router.navigate(['/login'], { queryParams: { ops: opsParameter } });
                    return;
                } else {
                    this.router.navigate(['/login']);
                    return;
                }            
            }
        }
        catch (err) {            
            console.log(err)
        }
    }

    tr(text: string) {
        return this.localization.translate(text);
    }

}
