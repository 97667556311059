import { Injectable } from '@angular/core';
import { environment } from '../environments/environment.securecookie';
import * as crypto  from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(

    ) { }

    allowedDomains = [
        ".basware.com",
        ".baswaredev.com",
        ".baswareqa.com"
    ]

    //This function compares the domain from origin with allowed domains
    isAllowedToRedirect(origin){
        try {
            var hostname = new URL(origin).hostname;
            for (var i in this.allowedDomains) {
                var domain = this.allowedDomains[i];
                if (hostname.endsWith(domain)) {
                    return true;
                }
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    getParameterByName(name) {
        if(name == "languagecode" || name == "confirmation_code" || name == "pool_id" || name == "origin"|| name == "target"|| name == "redirect" || name == "error_description"){
            var url = new URL(window.location.href);
            console.log("paramvalue",name)
            console.log("returnvalue",url.searchParams.get(name))
            return url.searchParams.get(name);
        }
        var url = new URL(window.location.href);
        const params = new URLSearchParams(url.search.toLowerCase());
        name = name.toLowerCase();
        var returnvalue=params.get(name)
        console.log("paramvalue",name)
        console.log("returnvalue",returnvalue)
        return params.get(name);        
    }
    delete_cookie(name) {
        var domain = window.location.hostname.split('.').slice(-2).join('.');
        document.cookie = name +"=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;"+ " Domain=." + domain;
    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        var domain = window.location.hostname.split('.').slice(-2).join('.');

        var secureCookie = ' Secure;'

        if (environment.NO_SECURE_COOKIE)
        {
            secureCookie = '';
        }

        document.cookie = name + "=" + (value || "") + expires + "; " + secureCookie + " Domain=." + domain + "; Path=/";
    }
    decrypt(key, value)    {
        var decryptedValue = crypto.AES.decrypt(value.toString(crypto.enc.Utf8), key).toString(crypto.enc.Utf8);

        return decryptedValue;
    }
}
