import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button'
// import { MatBadgeModule } from '@angular/material/badge'
//import { MatStepperModule } from '@angular/material/stepper'
import { MatInputModule } from '@angular/material/input'
import { MatCardModule } from '@angular/material/card'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatMenuModule } from '@angular/material/menu'
//import { MatListModule } from '@angular/material/list'
import { MatIconModule } from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider'
//import { MatGridListModule } from '@angular/material/grid-list'
import { MatExpansionModule } from '@angular/material/expansion'
//import { MatTabsModule } from '@angular/material/tabs'
//import { MatFormFieldModule  } from '@angular/material/form-field';
//import { MatAutocompleteModule } from '@angular/material/autocomplete';
//import { MatDatepickerModule } from '@angular/material/datepicker';
//import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
//import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
//import { MatCheckboxModule } from '@angular/material/checkbox';
//import { MatSortModule } from '@angular/material/sort';
//import { MatTooltipModule } from '@angular/material/tooltip';
//import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
//import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
//import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';

const material = [
  MatButtonModule,
//   MatBadgeModule,
//  MatStepperModule,
  MatInputModule,
//  MatFormFieldModule,
  MatCardModule,
  MatToolbarModule,
  MatMenuModule,
//  MatListModule,
  MatIconModule,
  MatDividerModule,
//  MatGridListModule,
  MatExpansionModule,
//  MatTabsModule,
//  MatAutocompleteModule,
//  MatDatepickerModule,
//  MatNativeDateModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
//  MatSlideToggleModule,
  MatSelectModule,
//  MatCheckboxModule,
//  MatSortModule,
//  MatTooltipModule,
//  MatButtonToggleModule,
  MatProgressSpinnerModule,
//  MatChipsModule,
  MatSnackBarModule,
//  MatProgressBarModule,
  MatRadioModule
];

@NgModule({
  imports: [material],
  exports: [material]
})
export class MaterialModule { }
