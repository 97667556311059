import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar/snackbar.component';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    constructor(private snackBar: MatSnackBar) {}
    public openSnackBar(message, type, duration?, verticalPosition?, horizontalPosition?) {
        const _snackType = type !== undefined ? type : 'success';
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: duration || 4000,
            horizontalPosition: horizontalPosition || 'center',
            verticalPosition: verticalPosition || 'bottom',
            data: { message: message, snackType: _snackType, snackBar: this.snackBar }
        });
    }
}
