import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { CommonService } from '../common.service';
import { ConfigService } from '../config.service';
import { LocalizationService } from '../localization.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';

const DEFAULT_LOCALE = "en-US";

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    constructor(
        private data: DataService,
        public common: CommonService,
        private config: ConfigService,
        private localization: LocalizationService
    ) {
        common.title = this.tr('access.changePassword.title');
        common.clearError();
    }

    success = false;
    isTextFieldTypeOld = false;
    isTextFieldTypeNew = false;
    codeRequired = false;
    invalidCode = false;
    codeSent = false;
    codeText = "";
    user = null;
    oldpw = '';
    newpw = '';
    confirmpw = '';
    submissionDisabled = true;

    email;
    emailFieldHidden = false;
    oldPasswordRequired = false;
    newPasswordRequired = false;
    usernameRequired = false;

    mode: ProgressSpinnerMode = 'indeterminate';
    value = 32;
    color: ThemePalette = 'primary';

    newPasswordErrorMessage = this.tr('access.login.changePassword.enterNewPassword');

    ngOnInit() {
        let idToken = localStorage.getItem('idToken')
        let amplifyInfo = localStorage.getItem('amplifyInfo')  

        let email = ""
        if (idToken) {
            let decodedIdToken =  new JwtHelperService().decodeToken(idToken)
            email = decodedIdToken.email
        }
        else if(amplifyInfo) {
            try {
                let amplifyInfoParsed = JSON.parse(amplifyInfo);
                let clientId = amplifyInfoParsed.client_id
                let lastAuthUser = localStorage.getItem('CognitoIdentityServiceProvider.' + clientId + ".LastAuthUser");
                if (lastAuthUser) {
                    let userData = localStorage.getItem('CognitoIdentityServiceProvider.' + clientId + "." + lastAuthUser + ".userData")
                    if (userData) {
                        let userDataParsed = JSON.parse(userData);
                        if (userDataParsed) {
                            for (let element of userDataParsed.UserAttributes) {
                                if (element.Name == "email") {
                                    email = element.Value
                                    break
                                }
                            }
                        }
                    }
                }
            }
            catch (err) {
                console.log(err)
            }
        }

        if (email != "") {
            this.emailFieldHidden = true
            this.email = email
        }
        else{
            if (this.config.getParameterByName("email")) {
                this.emailFieldHidden = true
                this.email = this.config.getParameterByName("email");
            }
        }
    }

    changeUsername() {
        this.emailFieldHidden = false
    }

    togglePasswordFieldTypeOld() {
        this.isTextFieldTypeOld = !this.isTextFieldTypeOld;
    }

    togglePasswordFieldTypeNew() {
        this.isTextFieldTypeNew = !this.isTextFieldTypeNew;
    }

    accessRedirectOrigin() {
        try {
            let origin = this.config.getParameterByName('origin');
            if (origin && this.config.isAllowedToRedirect(origin)) {
                window.location.href = decodeURIComponent(origin);;
            }
            else {
                this.success = false
                this.common.setError(this.tr('access.changePassword.cancel.NoOrigin'));
            }
        }
        catch (err) {
            this.common.setError(this.tr('access.common.notAllowed'));
        }
    }

    accessRedirect() {
        try {
            var origin = this.config.getParameterByName('origin');
            if (origin && this.config.isAllowedToRedirect(origin)) {
                window.location.href = decodeURIComponent(origin);;
            }
            else {
                this.success = true
                this.common.setError(this.tr('access.changePassword.successNoOrigin'));
            }
        }
        catch (err) {
            this.common.setError(this.tr('access.common.notAllowed'));
        }
    }

    async changePasswordWithCode(code){
        this.common.beginProgress();
        if(code == ""){
            this.codeRequired = true;
            this.common.stopProgress();
            return;
        }
        try {
            await this.data.confirmSignIn(this.user, code, this.user.challengeName === 'SMS_MFA' ? 'SMS_MFA' : 'SOFTWARE_TOKEN_MFA')
        } catch (e) {
            if (e.name === 'CodeMismatchException') {
                this.common.setError(this.tr('access.changePassword.incorrect.code'));
            } else {
                this.common.setError(e.message);
            }
            this.common.stopProgress();
            return;
        }
        let locale = this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode'): DEFAULT_LOCALE;
        await this.data.changePassword(this.user, locale, this.oldpw, this.newpw);
        this.accessRedirect();
        this.common.clearError();
        this.common.stopProgress();
    }

    private maskPhoneumber(phone) {
        let last4 = phone.substring(phone.length - 4);
        let mask = phone.substring(4, phone.length - 5).replace(/\d/g, "*");
        return mask + last4;
    }

    validateUsername(username: string) {
        if(username == ""){
            this.usernameRequired = true;
        } else {
            this.usernameRequired = false;
        }
        this.checkAllFields()
    }

    validateOldPassword(oldpw: string, newpw:string) {
        if (oldpw == "") {
            this.oldPasswordRequired = true;
        } else {
            this.oldPasswordRequired = false;
        }
        if(newpw == "") {
            this.submissionDisabled = true;
            this.newPasswordRequired = true;
            this.newPasswordErrorMessage = this.tr('access.login.changePassword.enterNewPassword');
        }
        this.checkAllFields();
    }

    regExpAtLeast1Numeric = new RegExp(/[0-9]/);
    regExpAtLeast1SpecialCharacter = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);

    validateNewPassword(newpw: string, oldpw: string) {
        if (newpw == "") {
            this.newPasswordRequired = true;
            this.newPasswordErrorMessage = this.tr('access.login.changePassword.enterNewPassword')
        } else {
            let numeric = this.regExpAtLeast1Numeric.test(newpw);
            let upper = newpw != newpw.toLowerCase() ? true : false;
            let lower = newpw != newpw.toUpperCase() ? true : false;
            let minLen = newpw.length >= 8 ? true : false;
            let specialChar = this.regExpAtLeast1SpecialCharacter.test(newpw);
            let all = numeric && upper && specialChar && minLen && lower ? true : false;
            //this.newPasswordRequired = !all;
            this.newPasswordRequired = !all;
            this.newPasswordErrorMessage = this.tr('access.login.changePassword.policy')
        }
        if (oldpw == "") {
            this.oldPasswordRequired = true;
        } else {
            this.oldPasswordRequired = false;
        }
        this.checkAllFields();
    }

    checkAllFields() {
        if (this.usernameRequired || this.newPasswordRequired || this.oldPasswordRequired) {
            this.submissionDisabled = true;
        } else {
            this.submissionDisabled = false;
        }
    }

    async changePassword(username: string, oldpw: string, newpw: string) {

        this.common.clearError();

        //this.common.setError('Required values missing');
        this.validateUsername(username);
        this.validateOldPassword(oldpw, newpw);
        this.validateNewPassword(newpw, oldpw);

        if(username == "" || oldpw == "" || newpw == "") {
            this.submissionDisabled = true;
            if (newpw == "") {
                this.newPasswordErrorMessage = this.tr('access.login.changePassword.enterNewPassword')
            }
            return;
        }

        if (this.usernameRequired || this.newPasswordRequired || this.oldPasswordRequired) {
            this.submissionDisabled = true;
            return;
        }

        username = username.toLowerCase();
        this.submissionDisabled = false;
        this.common.beginProgress();
        try {
            let discoveredResponse = await this.data.discover(username, this.config.getParameterByName('customerID') || "", "CHANGE-PWD").toPromise();
            let decodedToken = new JwtHelperService().decodeToken(discoveredResponse.token);
            let discovered = decodedToken.userData ? JSON.parse(decodedToken.userData) : {};
            let clientId = discovered.client_id;
            let userPool = discovered.user_pool;
            let applications = discovered.applications;
            // Check user discovery
            if (!discovered)
                throw new Error('No such user');

            // Configure amplify with given credentials
            this.data.configureAmplify(clientId, userPool);

            //
            let amplifyInfo;
            if (!localStorage.getItem('amplifyInfo')) {
                amplifyInfo = {
                    client_id: clientId,
                    user_pool: userPool,                    
                    customer_id: discovered.customer_id ? discovered.customer_id : "NA",
                    customer_short_name: discovered.customer_short_name ? discovered.customer_short_name : "NA",
                    allApplications: applications
                };
                localStorage.setItem("amplifyInfo", JSON.stringify(amplifyInfo));
            } else {
                amplifyInfo = {
                    client_id: clientId,
                    user_pool: userPool,
                    customer_id: discovered.customer_id ? discovered.customer_id : 'NA',
                    customer_short_name: discovered.customer_short_name ? discovered.customer_short_name : 'NA',
                    allApplications: applications
                };
                amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
                amplifyInfo.customer_id = discovered.customer_id ? discovered.customer_id : 'NA';
                amplifyInfo.customer_short_name = discovered.customer_short_name ? discovered.customer_short_name : 'NA';
                amplifyInfo.allApplications = discovered.allApplications;
                localStorage.setItem('amplifyInfo', JSON.stringify(amplifyInfo));
            }

            // Change password

            this.user  = await this.data.login(username, oldpw, {
                user_qp_locale: this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
                user_qp_origin: this.config.getParameterByName('origin') ? this.config.getParameterByName('origin') : 'NA',
                user_qp_email: this.config.getParameterByName('email') ? this.config.getParameterByName('email') : 'NA'
            });
            if (this.user.challengeName === 'SMS_MFA' || this.user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                this.codeSent = true;
                this.codeText = this.user.challengeName === 'SMS_MFA' ? this.tr('access.login.codePhoneText') + this.maskPhoneumber(this.user.challengeParam.CODE_DELIVERY_DESTINATION) : this.tr('access.login.codeAppText');
                this.common.stopProgress();
                this.oldpw =  oldpw;
                this.newpw = newpw;
                return;
            }else{
                let locale = this.localization.resolveLocale();
                await this.data.changePassword(this.user, locale, oldpw, newpw);
            }
            this.accessRedirect();
            this.common.clearError();
        }
        catch (e) {
            if (e.name == 'InvalidParameterException') {
                this.common.setError(this.tr('access.changePassword.validationError'));
            } else {
                if (e.message === 'Incorrect username or password.' || e.message === 'Invalid UserPoolId format.') {
                    this.common.setError(this.tr('access.changePassword.incorrect.usernamePassword'));
                } else {
                    this.common.setError(e.message);
                }
            }
            this.common.stopProgress();
        }

        this.common.stopProgress();
    }

    tr(text: string) {
        return this.localization.translate(text);
    }
}
