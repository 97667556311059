import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import Auth from '@aws-amplify/auth';
import { CommonService } from '../common.service';
import { ConfigService } from '../config.service';
import { LocalizationService } from '../localization.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
import {JwtHelperService} from '@auth0/angular-jwt';
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
    constructor(
        public data: DataService,
        private config: ConfigService,
        public common: CommonService,
        private localization: LocalizationService,
        private router: Router,
        private  location: Location,
    ) {
        common.title = this.tr("access.forgot.titleText");
        common.clearError();
    }

    discovered = false;
    codeFound = false;
    code = '';
    username = '';
    pool_id = '';
    client_id = '';
    expired = false;
    codeReq = false;
    followText = '';
    codeSent = false;
    passwordRequired = false;
    passwordErrorMessage = this.tr('access.login.forgotPassword.enterPassword');
    submissionDisabled = true;
    showLogin = false;

    mode: ProgressSpinnerMode = 'indeterminate';
    value = 32;
    color: ThemePalette = 'primary';


    async ngOnInit() {
        var code = this.config.getParameterByName("confirmation_code");
        var username = decodeURIComponent(this.config.getParameterByName("user_name"));
        if (code && username) {
            this.code = code;
            this.username = username.toLowerCase();
            this.data.username = username;
            this.codeFound = true;
            this.discovered = true;
            this.common.title = this.tr("access.forgot.Submit");
            this.client_id = decodeURIComponent(this.config.getParameterByName("client_id"));
            this.pool_id = decodeURIComponent(this.config.getParameterByName("pool_id"));
            await this.checkExpiry();
        } else if (this.data.username) {
            this.username = this.data.username;
        }
    }
    async checkExpiry() {
        var origTime = decodeURIComponent(this.config.getParameterByName("timestamp"));
        var currTime = moment.utc().toDate();
        var duration = moment.duration(moment(currTime).diff(moment(parseInt(origTime))));
        var minutes = duration.asMinutes();
        if (minutes > 59) {
            this.data.configureAmplify(this.client_id, this.pool_id);
            if (!this.expired) {
                if(this.config.getParameterByName("flow") && this.config.getParameterByName("flow") == "actacc"){
                    await this.data.setUserPassword(this.username, "", this.pool_id);               
                }
                await Auth.forgotPassword(this.username, {
                    customer_id: "NA",
                    customer_short_name: "NA",
                    device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey'): "NA",
                    user_qp_locale: this.config.getParameterByName('languagecode') ? decodeURIComponent(this.config.getParameterByName('languagecode')) : 'en-US',
                    user_qp_origin: this.config.getParameterByName('origin') ? decodeURIComponent(this.config.getParameterByName('origin')) : 'NA',
                    user_qp_pool_id: this.config.getParameterByName('pool_id') ? decodeURIComponent(this.config.getParameterByName('pool_id')) : 'NA',
                    user_qp_timestamp: this.config.getParameterByName('timestamp') ? decodeURIComponent(this.config.getParameterByName('timestamp')) : 'NA',
                    user_qp_app: decodeURIComponent(this.config.getParameterByName('application'))
                });
                this.expired = true;
            }
        }
    }

    resetDiscovery() {
        this.data.username = "";
    }

    backToPrevious(): void {
        this.location.back();
    }

    accessRedirect() {

        let origin = decodeURIComponent(this.config.getParameterByName('origin'));
        let application = decodeURIComponent(this.config.getParameterByName("application"));
        if (origin && this.config.isAllowedToRedirect(origin)) {
            location.href = origin;
        }
        else if(application && application == 'cloudscan') {
            this.router.navigateByUrl('/applications?application='+application+'&flow=fp');
        }
        else {
            let opsParameter = this.config.getParameterByName("ops");
            if (opsParameter) {
                let loginType = "emailandpwd";
                this.router.navigate(['/login'], { queryParams: { ops: opsParameter, loginType: loginType } });
            } else {
                this.router.navigate(['/login']);
            }  
        }
    }

    async changePasswordForUser(password: string) {

        if(password == "") {
            this.submissionDisabled = true;            
            this.passwordErrorMessage = this.tr('access.login.forgotPassword.enterPassword')            
            return;
        }
        
        // if(password == ""){
        //     this.passwordRequired = true;
        //     return;
        // }
        this.changePassword(this.username, this.code, this.pool_id, this.client_id, password);
    }

    buttonText() {
        if (!this.discovered)
            return this.tr('access.forgot.sendRequest');
        else
            return this.tr('access.forgot.Submit');
    }

    async changePassword(username: string, code: string, pool_id: string, client_id: string, password: string) {
        try {
            username = username.toLowerCase();
            this.common.clearError();
            this.common.beginProgress();
            
            if(this.config.getParameterByName("flow") && this.config.getParameterByName("flow") == "actacc"){
                await this.data.setUserPassword(username, password, pool_id);               
            }
            else {
                if (pool_id && client_id) {
                    this.data.configureAmplify(client_id, pool_id);
                }
                await Auth.forgotPasswordSubmit(username, code, password, {
                    ...this.data.getCustomerIdAndShortName(),
                    device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey'): "NA"
                });
            }
            
            this.data.resetSuccessful = true;
            this.data.username = username;
            this.data.password = password;
            this.data.updateEntryPoint("changePassword");            
            if(this.config.getParameterByName('application')) {
                let locale = this.localization.resolveLocale();                
                await this.data.changePasswordEmailAfterReset(username, locale, pool_id);
            }
            this.accessRedirect();
        } catch (e) {
            if (e.name == 'InvalidPasswordException') {
                this.common.setError(e.message);
            }
            else if (e.name == 'InvalidParameterException' && e.message.indexOf("'password' failed to satisfy constraint") != -1) {
                this.common.setError(this.tr('access.forgot.passwordValidation'));
            } else if (e.name == "ExpiredCodeException") {
                this.common.setError('access.forgot.expired')
                await Auth.forgotPassword(username, {
                    ...this.data.getCustomerIdAndShortName(),
                    user_qp_locale: this.config.getParameterByName('languagecode') ? decodeURIComponent(this.config.getParameterByName('languagecode')) : 'en-US',
                    user_qp_origin: this.config.getParameterByName('origin') ? decodeURIComponent(this.config.getParameterByName('origin')) : 'NA',
                    user_qp_pool_id: this.config.getParameterByName('pool_id') ? decodeURIComponent(this.config.getParameterByName('pool_id')) : 'NA',
                    user_qp_timestamp: this.config.getParameterByName('timestamp') ? decodeURIComponent(this.config.getParameterByName('timestamp')) : 'NA',
                    device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey'): "NA",
                    user_qp_app: decodeURIComponent(this.config.getParameterByName('application'))
                });
                this.expired = true;
            }
            else {
                console.log(e);
                this.common.setError(this.tr('access.forgot.error'));
            }
        }

        this.common.stopProgress();
    }

    async discover(email: string) {
        this.common.beginProgress();
        try {
            let username = email.toLowerCase();
            let discoveredResponse = await this.data.discover(username, this.config.getParameterByName('customerID') || "", "FORGOT-PWD").toPromise();
            let decodedToken = new JwtHelperService().decodeToken(discoveredResponse.token); 
            let discovered = decodedToken.userData ? JSON.parse(decodedToken.userData) : {};
            let loginType = discovered.loginType;
            let clientId = discovered.client_id;
            let userPool = discovered.user_pool;
            let applications = discovered.applications;
            this.data.configureAmplify(clientId, userPool);
            this.followText = this.tr('access.forgot.followText');
            let amplifyInfo;
            if (!localStorage.getItem('amplifyInfo')) {
                amplifyInfo = {
                    client_id: clientId,
                    user_pool: userPool,
                    skip_mfa_enabled: discovered.hasOwnProperty("skip_mfa_enabled") ? discovered.skip_mfa_enabled : false,
                    customer_id: discovered.customer_id ? discovered.customer_id : "NA",
                    customer_short_name: discovered.customer_short_name ? discovered.customer_short_name : "NA",
                    allApplications: applications
                };
                localStorage.setItem("amplifyInfo", JSON.stringify(amplifyInfo));
            } else {
                amplifyInfo = {
                    client_id: clientId,
                    user_pool: userPool,
                    skip_mfa_enabled: discovered.hasOwnProperty('skip_mfa_enabled') ? discovered.skip_mfa_enabled : false,
                    customer_id: discovered.customer_id ? discovered.customer_id : 'NA',
                    customer_short_name: discovered.customer_short_name ? discovered.customer_short_name : 'NA',
                    allApplications: applications
                };
                amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
                amplifyInfo.skip_mfa_enabled = discovered.hasOwnProperty('skip_mfa_enabled') ? discovered.skip_mfa_enabled : false;
                amplifyInfo.customer_id = discovered.customer_id ? discovered.customer_id : 'NA';
                amplifyInfo.customer_short_name = discovered.customer_short_name ? discovered.customer_short_name : 'NA';
                amplifyInfo.allApplications = discovered.allApplications;
                localStorage.setItem('amplifyInfo', JSON.stringify(amplifyInfo));
            }
            
            if(userPool.toLowerCase() != "none" && loginType.toLowerCase() == "cognito")
                await this.data.setUserPassword(username, "", userPool);

            await Auth.forgotPassword(username, {
                customer_id: amplifyInfo.customer_id ? amplifyInfo.customer_id : "NA",
                customer_short_name: amplifyInfo.customer_short_name ? amplifyInfo.customer_short_name : "NA",
                device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey'): "NA",
                user_qp_locale: this.config.getParameterByName('languagecode') ? decodeURIComponent(this.config.getParameterByName('languagecode')) : 'en-US',
                user_qp_origin: this.config.getParameterByName('origin') ? decodeURIComponent(this.config.getParameterByName('origin')) : 'NA',
                user_qp_pool_id: this.config.getParameterByName('pool_id') ? decodeURIComponent(this.config.getParameterByName('pool_id')) : 'NA',
                user_qp_timestamp: this.config.getParameterByName('timestamp') ? decodeURIComponent(this.config.getParameterByName('timestamp')) : 'NA',
                user_qp_app: decodeURIComponent(this.config.getParameterByName('application'))
            });
            this.discovered = true;
            this.codeSent = true;
            if(!this.config.getParameterByName('application'))
                this.showLogin = true;            
        }
        catch (err) {
            this.discovered = true;
            this.followText = this.tr('access.forgot.followText');
            this.codeSent = true;
            if(!this.config.getParameterByName('application'))
                this.showLogin = true;
        }
        this.common.stopProgress();
    }

    private maskPhoneumber(phone) {
        let last4 = phone.substring(phone.length - 4);
        let mask = phone.substring(4, phone.length - 5).replace(/\d/g, "*");
        return mask + last4;
    }


    tr(text: string) {
        return this.localization.translate(text);
    }

    regExpAtLeast1Numeric = new RegExp(/[0-9]/);
    regExpAtLeast1SpecialCharacter = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);

    validateNewPassword(newpw: string) {
        if (newpw == "") {
            this.passwordRequired = true;
            this.passwordErrorMessage = this.tr('access.login.forgotPassword.enterPassword')
        } else {
            let numeric = this.regExpAtLeast1Numeric.test(newpw);
            let upper = newpw != newpw.toLowerCase() ? true : false;
            let lower = newpw != newpw.toUpperCase() ? true : false;
            let minLen = newpw.length >= 8 ? true : false;
            let specialChar = this.regExpAtLeast1SpecialCharacter.test(newpw);
            let all = numeric && upper && specialChar && minLen && lower ? true : false;            
            this.passwordRequired = !all;
            this.passwordErrorMessage = this.tr('access.login.changePassword.policy')
        }        
        this.checkAllFields();
    }

    checkAllFields() {
        if (this.passwordRequired) {
            this.submissionDisabled = true;
        } else {
            this.submissionDisabled = false;
        }
    }
}
