import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { AppComponent } from './app.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

//import { MatButtonModule } from '@angular/material/button';
import { LocalizationService } from './localization.service';

//import { MatRadioModule } from '@angular/material/radio';
//import { MatInputModule } from '@angular/material/input';
//import { MatCardModule } from '@angular/material/card';
//import { MatSelectModule } from '@angular/material/select';
//import { MatExpansionModule } from '@angular/material/expansion';
//import { MatTableModule } from '@angular/material/table';
//import {MatIconModule} from '@angular/material/icon';
//import {MatMenuModule} from '@angular/material/menu';
import { QRCodeModule } from 'angularx-qrcode';
//import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialModule } from './material.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { ReauthenticateFormComponent } from './reauthenticate/reauthenticate-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CalloutComponent } from './callout/callout.component';
import { LogoutComponent } from './logout/logout.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PasswordPolicyComponent } from './passwordpolicy/passwordpolicy.component';
import { MFAComponent } from './mfa/mfa.component';
import { TurnOffDialog } from './mfa/mfa.component';
import {SnackbarComponent} from "./snackbar/snackbar.component";
import {SnackBarService} from "./snack-bar.service";
import { ApplicationsComponent } from './applications/applications.component';
import { ResetMfaComponent } from './resetmfa/resetmfa.component';
import {CommonModule} from "@angular/common";
import {TenantsComponent} from "./Tenants/tenants.component";
export function appInit(appConfigService: LocalizationService) {
  return () => appConfigService.setLang();
}

@NgModule({
    declarations: [
        AppComponent,
        LoginFormComponent,
        ForgotPasswordComponent,
        CalloutComponent,
        LogoutComponent,
        ChangePasswordComponent,
        MFAComponent,
        TurnOffDialog,
        SnackbarComponent,
        PasswordPolicyComponent,
        HeaderComponent,
        ReauthenticateFormComponent,
        ApplicationsComponent,
        TenantsComponent,
        ResetMfaComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        //MatRadioModule,
        //MatButtonModule,
        //MatInputModule,
        //MatTableModule,
        //MatPaginatorModule,
        //MatCardModule,
        //MatSelectModule,
        //MatExpansionModule,
        //MatTableModule,
        //MatIconModule,
        //MatMenuModule,
        //MatDialogModule,
        //MatToolbarModule,
        ReactiveFormsModule,
        //MatProgressSpinnerModule,
        HttpClientModule,
        //MatSnackBarModule,
        //MatDividerModule,
        QRCodeModule,
        MaterialModule,
    ],
    providers: [LocalizationService,
        SnackBarService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            multi: true,
            deps: [LocalizationService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
