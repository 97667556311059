import { Component, OnInit, Input } from '@angular/core';
import { LocalizationService } from '../localization.service';

@Component({
    selector: 'app-password-policy',
    templateUrl: './passwordpolicy.component.html',
    styleUrls: ['./passwordpolicy.component.scss']
})

export class PasswordPolicyComponent {
    @Input() set val(val: string) {
        this.checkPassword(val);
    }
    constructor(
        private localization: LocalizationService
    ) {

    }
    numeric = false;
    minLen = false;
    specialChar = false;
    upper = false;
    lower = false;
    all = false;

    checkPassword = function (val: string) {
        const regExpAtLeast1Numeric = new RegExp(/[0-9]/);
        const regExpAtLeast1SpecialCharacter = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
        this.numeric = regExpAtLeast1Numeric.test(val);
        this.upper = val != val.toLowerCase() ? true : false;
        this.lower = val != val.toUpperCase() ? true : false;
        this.minLen = val.length >= 8 ? true : false;
        this.specialChar = regExpAtLeast1SpecialCharacter.test(val);
        this.all = this.numeric && this.upper && this.specialChar && this.minLen && this.lower ? true : false;
    }

    tr(text: string) {
        return this.localization.translate(text);
    }
}
