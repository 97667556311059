import {AfterViewChecked, ChangeDetectorRef, Component} from '@angular/core';
import { LocalizationService } from './localization.service';
import { CommonService } from './common.service';
import {Subscription} from "rxjs";
import {DataService} from "./data.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked{

    isGraviton:boolean = false;
    subscription: Subscription;
 // message: Array<Message> ;
//  config: MessagePanelConfig;

  constructor(
    private localization: LocalizationService,
    public common: CommonService,
    private ds: DataService,
    private cdRef : ChangeDetectorRef
    
  ) {
      this.subscription = this.ds.getData().subscribe(value => {  
          if(value == true) {
              this.isGraviton = true;
              document.querySelector('body').classList.add('transparent-background');
          }
      });
    // this.message    =[{
    //   message: '',
    //   type: 'error',
    //   allowClosing: false
    // }];
    // this.config  = {
    //   allowGroupClose: false,
    // popoverLinkTitle: "View All",
    // messageGroupTitles: {
    //   error: '',
    //   'system-error': '',
    //   warning: '',
    //   info: '',
    //   success: '',
    //   note: '',
    //   "info-message": ''
    //   },
    //   useRootContainer: false
    // };
  }

  ngOnInit() {
  //   this.common.currentMessage.subscribe(message => {console.log(message); this.message[0].message = this.tr(message)})
      var amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
      if(amplifyInfo) {
          if (amplifyInfo.isOpsUser) {
         // this.isGraviton = true;
          }
      }
   }

    ngAfterViewChecked() {
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    }

  tr(text: string) {
    return this.localization.translate(text);
  }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

}
